import React from 'react'
import styled from 'styled-components'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/layout'
import SEO from 'components/shared/SEO'
import Navigation from 'components/layout/Navigation'
import Footer from 'components/layout/Footer'

import Container from 'components/shared/container'
import LazyImage from 'components/shared/lazyImage'
import { Heading, Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;

  ${({ theme }) => theme.media.lg.min} {
    margin-top: 130px;
  }
`

const Header = styled.header`
  width: 100%;

  div {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 560px;
  }
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 30px 0;

  max-width: 680px;

  ${({ theme }) => theme.media.lg.min} {
    margin: 50px 0;
    max-width: unset;
  }
`

const StyledContainer = styled(Container)`
  ${({ theme }) => theme.media.lg.min} {
    display: flex;
    align-items: flex-start;
    max-width: 1140px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    max-width: 1360px;
  }
`

const TextContent = styled.section`
  width: 100%;

  ${({ theme }) => theme.media.lg.min} {
    width: 50%;
    margin-right: 10px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    margin-right: 40px;
  }
`

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px 0;
`

const PromoImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  ${({ theme }) => theme.media.lg.min} {
    width: 50%;
    margin-top: 0;
    margin-left: 10px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    margin-left: 40px;
  }
`

const Promotion: React.FC<PageProps<Queries.PromotionPageQuery>> = ({
  data,
}) => {
  const PAGE = data?.wpPage?.stronaGlowna
  const PAGE_SEO = data?.wpPromotionArticle?.seo
  const PROMOTION_DATA = data?.wpPromotionArticle?.promotion

  const heroImg = data?.heroImg?.childImageSharp?.gatsbyImageData!
  const heroImgMobile = data?.heroImgMobile?.childImageSharp?.gatsbyImageData!

  const { md } = useBreakpoint()

  if (!PAGE || !PROMOTION_DATA || !PAGE_SEO)
    throw new Error("CMS data didn't load properly")

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Wrapper>
        <Header>
          <LazyImage src={md ? heroImg : heroImgMobile} alt="hero" />
        </Header>
        <Main>
          <StyledContainer>
            <TextContent>
              <Heading
                size={38}
                weight={700}
                transform="uppercase"
                dangerouslySetInnerHTML={{ __html: PROMOTION_DATA?.title! }}
                margin="0"
              />
              <DateWrapper>
                <Heading
                  as="h2"
                  size={28}
                  weight={600}
                  transform="uppercase"
                  themecolor="danger100"
                  dangerouslySetInnerHTML={{ __html: 'data' }}
                  margin="10px"
                />
                <Heading
                  as="h3"
                  size={26}
                  weight={600}
                  transform="uppercase"
                  themecolor="dark"
                  dangerouslySetInnerHTML={{
                    __html: PROMOTION_DATA?.duration!,
                  }}
                  margin="0"
                />
              </DateWrapper>
              <Text
                size={16}
                weight={400}
                themecolor="dark"
                dangerouslySetInnerHTML={{
                  __html: PROMOTION_DATA?.description!,
                }}
              />
            </TextContent>
            <PromoImg>
              <LazyImage
                src={
                  PROMOTION_DATA?.img?.localFile?.childImageSharp
                    ?.gatsbyImageData!
                }
                alt="promotion"
              />
            </PromoImg>
          </StyledContainer>
        </Main>
        <Footer
          salesOffice={{
            openingHours:
              PAGE?.contactSalesOffice?.contactSalesOfficeOpeningHours!,
            phone: PAGE?.contactSalesOffice?.contactSalesOfficePhoneNumber!,
            email: PAGE?.contactSalesOffice?.contactSalesOfficeEMail!,
          }}
        />
      </Wrapper>
    </Layout>
  )
}

export default Promotion

export const query = graphql`
  query PromotionPage($title: String!) {
    heroImg: file(name: { eq: "hero-promocja" }) {
      childImageSharp {
        gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
      }
    }
    heroImgMobile: file(name: { eq: "hero-promocja-mobile" }) {
      childImageSharp {
        gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
      }
    }
    wpPromotionArticle(slug: { eq: $title }) {
      promotion {
        title
        duration
        description
        img {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
    }
    wpPage {
      stronaGlowna {
        contactSalesOffice {
          contactSalesOfficeEMail
          contactSalesOfficeOpeningHours
          contactSalesOfficePhoneNumber
        }
      }
    }
  }
`
