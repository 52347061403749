import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import Icon from 'components/shared/icon'
import { Heading, Text } from 'components/shared/typography'
import dorGroupIcon from 'assets/icons/dorgroup.svg'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  heading?: string
  salesOffice: {
    openingHours: string
    phone: string
    email: string
  }
}

const StyledFooter = styled.footer`
  width: 100%;
`

const StyledHeading = styled(Heading)`
  letter-spacing: 5px;
  margin: 4.25rem 0 3.5rem 0;

  ${({ theme }) => theme.media.lg.min} {
    margin: 8rem 0 2rem 0;
  }
`

const StyledContainer = styled(Container)`
  max-width: unset;
  margin-inline: auto;
  background-color: #99c753;
  padding-bottom: 4rem;

  ${({ theme }) => theme.media.lg.min} {
    padding-top: 4rem;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 290px;
  margin-inline: auto;

  ${({ theme }) => theme.media.lg.min} {
    padding-left: ${({ theme }) => theme.container.paddingMD};
    padding-right: ${({ theme }) => theme.container.paddingMD};
    max-width: ${({ theme }) =>
      `calc(${theme.container.width} + (${theme.container.padding} * 2))`};

    ${Text} {
      width: max-content;
    }
  }

  ${({ theme }) => theme.media.xxxl.min} {
    max-width: ${({ theme }) =>
      `calc(${theme.container.widthXXXL} + (${theme.container.padding} * 2))`};
  }
`

const OfficeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.media.lg.min} {
    align-items: center;
    padding: 0 20%;
  }

  ${({ theme }) => theme.media.xl.min} {
    padding: 0 25%;
  }
`

const OfficeHeader = styled.div`
  width: 100%;
  padding-top: 4rem;
  padding-bottom: 3rem;
  margin-bottom: 4rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.white};
`

const DorGroupLogo = styled.div`
  width: 100%;
  max-width: 120px;
  margin-top: 3rem;
  & > div {
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.media.lg.min} {
    max-width: 140px;
  }
`

const Footer: React.FC<Props> = ({ heading, salesOffice }) => {
  const { lg, xxl } = useBreakpoint()

  return (
    <StyledFooter>
      {heading && (
        <StyledHeading
          as="h2"
          size={lg ? 22 : 28}
          weight={500}
          themecolor="primary"
          margin="0"
          align="center"
          transform="uppercase"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
      )}
      <StyledContainer>
        <InnerWrapper>
          <OfficeWrapper>
            <OfficeHeader>
              <Heading
                as="h4"
                size={xxl ? 24 : 30}
                weight={700}
                themecolor="white"
                margin="0"
                transform="uppercase"
                align={lg ? 'center' : 'left'}
                dangerouslySetInnerHTML={{ __html: 'property manager' }}
              />
            </OfficeHeader>
            <Text
              as="a"
              href={`tel: ${salesOffice.phone}`}
              size={xxl ? 15 : 16}
              weight={500}
              themecolor="white"
              margin="5px"
              dangerouslySetInnerHTML={{
                __html: `Tel.: ${salesOffice.phone}`,
              }}
            />
            <Text
              size={xxl ? 15 : 16}
              weight={500}
              themecolor="white"
              margin="5px"
              dangerouslySetInnerHTML={{
                __html: `pn. - pt.: ${salesOffice.openingHours}`,
              }}
            />
            <Text
              as="a"
              href={`mailto: ${salesOffice.email}`}
              size={xxl ? 15 : 16}
              weight={500}
              themecolor="white"
              margin="5px"
              dangerouslySetInnerHTML={{
                __html: salesOffice.email,
              }}
            />
          </OfficeWrapper>
          <DorGroupLogo>
            <Icon src={dorGroupIcon} auto alt="DorGroup" />
          </DorGroupLogo>
        </InnerWrapper>
      </StyledContainer>
    </StyledFooter>
  )
}

export default Footer
